const getLastDayOfMonth = (year, month) => {
  return new Date(year, month + 1, 0).getDate();
}

export const SummaryData = (doc, margin, startPositionY, selectDate, dataSummary) => {

    const date = new Date(selectDate)
    const monthText = date.toLocaleString('default', { month: 'long' })
    const lastDate = getLastDayOfMonth(date.getFullYear(), date.getMonth())

    let data = []
    const headers = [["Summary", ""]];
    const foots = [["Total for this invoice", dataSummary[0].total.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      })]];

    dataSummary?.map(elt => data.push(
        [elt.service, elt.total.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
          })],
        ["Charges", elt.charge.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
          })],
        ["VAT", elt.vat.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
          })],
        ["Credits",0], 
        ["Tax",0])
    );

    const title = "This invoice is for the billing period "
    const dateText = `${monthText} ${date.getDate()} - ${monthText} ${lastDate}, ${date.getFullYear()}`

    doc.setFontSize(15);
    doc.text(title+dateText, margin, startPositionY+50);

    return {
        startY: startPositionY+60,
        head: headers,
        body: data,
        foot: foots,
        columnStyles: {
            1: {halign: "right"}
        },
        didParseCell: function (data) {
            if (data.row.index === 0 && data.row.section === 'body') {
                data.cell.styles.fontSize = 12;
                data.cell.styles.cellPadding = [5,5,5,15];
            }else if(data.row.section === 'head'){
                data.cell.styles.fontSize = 13;
            }
            else if(data.row.section === 'foot'){
                data.cell.styles.fontSize = 13;
                if(data.column.index === 1){
                    data.cell.styles.halign = 'right';
                }
            }
            else{
                data.cell.styles.cellPadding = [5,5,5,25];
            }
        }
    }
};

export const DetailData = (lastY, dataDetail) => {
    const headers = [["Detail", ""]];
    const data = []

    dataDetail?.map(elt => data.push(
        [elt.service, elt.total.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
          })],
        ["Charges", elt.charge.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
          })], 
        ["VAT", elt.vat.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2
          })])
    );

    return {
        startY: lastY,
        showHead: 'firstPage',
        head: headers,
        body: data,
        columnStyles: {
            1: {halign: "right"}
        },
        didParseCell: function (data) {
            if (data.row.index%3 === 0 && data.row.section === 'body') {
                data.cell.styles.fontSize = 12;
                data.cell.styles.cellPadding = [5,5,5,15];
            }else if(data.row.section === 'head'){
                data.cell.styles.fontSize = 13;
            }
            else{
                data.cell.styles.cellPadding = [5,5,5,25];
            }
        }
    }
};