import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
	display: grid;
	grid-template-rows: max-content max-content max-content auto;
	padding: 32px 12px 12px 12px;
    background-color: skyblue;
`;

const StyledLink = styled.span`
	font-size: ${(props) => props.fontSize || "14px"};
	font-weight: 500;
	// text-decoration: none;
`;

const LinkSection = styled.a`
	display: grid;
	grid-template-columns: 3fr 7fr;
	align-items: center;
	padding: 8px 12px;
	border-radius: 4px;
	text-decoration: none;
	.active {
		display: none;
	}
`;

const CommonSection = styled.div`
	display: grid;
	grid-template-rows: min-content;
	row-gap: 16px;
`;


const CommonWasteSectionLink = [
	{
		name: "Billing",
		href: `/billing`,
	},
];

const CommonSidebar = ({ pathname, navigate }) => {
	return (
		<CommonSection>
			{CommonWasteSectionLink.map((item, i) => (
                <LinkSection
                    key={i}
                    href={item.href}
                    active={item.href === `/${pathname?.[1]}`}
                    onClick={() => {
                        navigate(null, { state: null });
                    }}
                >
                    <StyledLink>{item.name}</StyledLink>
                </LinkSection>
            ))}
		</CommonSection>
	);
};
const Sidebar = ({}) => {
	const location = useLocation();
	const pathname = location?.pathname.split("/");
	const navigate = useNavigate();

	return (
		<Container>
			<CommonSidebar pathname={pathname} navigate={navigate}></CommonSidebar>
		</Container>
	);
};

export default Sidebar;