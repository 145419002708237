import React, { useEffect, useState } from 'react';
import styled from 'styled-components'
import { DatePicker } from 'antd';
import jsPDF from "jspdf";
import "jspdf-autotable";

//api
import { getBilling } from '../../api';

//component
import { SummaryData, DetailData } from './body';
import { HeaderData, HeaderCompany, HeaderCustomer } from './header';

const Container = styled.div`
    text-align: center;
    padding: 2rem;
`
const Header = styled.div`
    display: grid;
    grid-template-columns: max-content max-content max-content auto;
    column-gap: 10px;
    align-items: center;
`
const StyledText = styled.span`
	font-size: ${(props) => props.fontSize || "16px"};
    font-weight: 600;
	display: block;
	overflow: hidden;
	word-wrap: break-word;
	text-overflow: ellipsis;
	white-space: nowrap;
`;

const StyledButton = styled.button`
    background: transparent;
    border-radius: 3px;
    border: 2px solid ${prop => prop.disabled ? 'gray' : 'palevioletred'};;
    color: ${prop => prop.disabled ? 'gray' : 'palevioletred'};
    margin: 0 1em;
    padding: 0.25em 1em;
`

const ExportInvoice = ({

}) => {
    const [selectDate, setSelectDate] = useState(null)
    const [data, setData] = useState(null)

    const fetchData = async () => {
		try {
			const res = await getBilling({selectDate: selectDate});
            setData(res.data.data)
		} catch (error) {
			return error;
		}
	}

    const exportPDF = async() => {
        await fetchData()
    }

    useEffect(()=> {
        if(data){
            generatePDF()
        }
    },[data])


    const generatePDF = async() => {
        console.log("dataPDF",data)
        const unit = "pt";
        const size = "A4"; 
        const orientation = "portrait";
    
        const margin = 40;
        let startPositionY = 0;
        const doc = new jsPDF(orientation, unit, size);

        //header
        startPositionY = await HeaderCompany(doc,margin,startPositionY)
        startPositionY = await HeaderCustomer(doc,margin,startPositionY)
        HeaderData(doc, startPositionY, selectDate)

        //body
        doc.autoTable(SummaryData(doc, margin, startPositionY, selectDate, data.summary));
        doc.autoTable(DetailData(doc.lastAutoTable.finalY + 20, data.detail));

        doc.output('dataurlnewwindow')
        // doc.save("report.pdf")
    }

    const onChange = (date,dateString) => {
        setSelectDate(dateString)
    };

    return(
        <Container>
             <StyledText fontSize="24px">
                Billing Invoice
            </StyledText>
            <Header>
                <StyledText>
                    Date
                </StyledText>
                <DatePicker onChange={onChange} picker="month" />
                <StyledButton
                    disabled={selectDate === null || selectDate === ""}
                    onClick={() => exportPDF()}
                >
                    Export
                </StyledButton>
            </Header>
        </Container>
    )
}

export default ExportInvoice