import { gapi } from "gapi-script"
import { useEffect, useState } from "react"
import {GoogleLogin, GoogleLogout} from "react-google-login"

const client_id = "174886384559-10c9i8s4t32qr5acleq2hso3mcm13i5t.apps.googleusercontent.com"

const Login = ({}) => {
    const [profile, setProfile] = useState()

    console.log('profile', profile)

    const onSuccess = (res) => {
        console.log('res', res)
        setProfile(res.profileObj)
    }
    
    useEffect(()=>{
        const start = () => {
            gapi.client.init({
                clientId: client_id,
                scope: 'https://www.googleapis.com/auth/drive.metadata.readonly',
                
            })
        }
        gapi.load('client:auth2', start)
    }, [])



    return(
        <div>
            <GoogleLogin 
                clientId={client_id} 
                buttonText="Login" 
                onSuccess={onSuccess} 
                onFailure={(res)=>{console.log('res', res)}} 
                cookiePolicy={"single-host-origin"} 
                isSignedIn={true}
            ></GoogleLogin>
            {profile?.email || null}
            {profile?.email ? <GoogleLogout
                clientId={client_id} 
                buttonText="Log out" 
                onLogoutSuccess={()=>setProfile(null)}  
            ></GoogleLogout> : null}
            
        </div>
    )
}

export default Login