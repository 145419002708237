import { useState } from "react";
import { getCheckRequest } from "../../api/checkRequest";

const CheckRequset = () => {
	const [testData, setTestData] = useState("no data");

	const fetchData = async () => {
		try {
			const res = await getCheckRequest();
			setTestData(res.data.data);
		} catch (error) {
			setTestData("failed");

			return error;
		}
	};

	return (
		<div>
			<button onClick={() => fetchData()}>check response</button>
			<div> {testData} </div>
		</div>
	);
};

export default CheckRequset;
