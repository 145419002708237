export const CompanyDetail = {
    name: 'Erudite Cloud Co.,Ltd.',
    address: '#1503 15/F SJ Infinite One Business Complex, 349 Vibhavadi-Rangsit Road, Chomphol, Chatuchak, Bangkok 10900, TH',
    phone: '+(66) 2026 3566',
    tax: '0105565157501',
}

export const CustomerDetail = {
    name: 'Erudite Engineering Service Co.,Ltd.',
    address: '#1504 15/F SJ Infinite One Business Complex, 349 Vibhavadi-Rangsit Road, Chomphol, Chatuchak, Bangkok 10900, TH',
    phone: '+(66) 2026 3566',
    tax: '0105565157501',
    accountNo: '718585668470',
    payDate: '15',
}