import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactDOM from "react-dom/client";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Outlet,
} from "react-router-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

//pages
import ExportInvoice from "./pages/invoice";
import Sidebar from "./components/Sidebar";
import Login from "./pages/login";
import CheckRequest from "./pages/checkRequest";

const Container = styled.div`
	display: grid;
	grid-template-columns: 216px 1fr;
	height: 100vh;
	overflow: auto;
`;

const WithSidebar = ({}) => {
	return (
		<Container>
			<Sidebar />
			<Outlet />
		</Container>
	);
};

const App = () => {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<WithSidebar />}>
					<Route path="/billing" element={<ExportInvoice />}></Route>
					<Route path="/login" element={<Login />} />
					<Route path="/check-request" element={<CheckRequest />} />
				</Route>
				<Route path="*" element={<></>}></Route>
			</Routes>
		</Router>
	);
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
