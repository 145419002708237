import { CompanyDetail,CustomerDetail } from "../../../utils/HeaderDetail";

export const HeaderData = (doc, startPositionY, selectDate) => {
    const convertDate = new Date(selectDate)
    const date = new Date(convertDate.setMonth(convertDate.getMonth()+1))
    const title = "INVOICE"
    const InvoiceNumberText = "Invoice Number: "
    const InvoiceNumber = '1159226625' // waiting recheck
    const InvoiceDateText = "Invoice Date: "
    const InvoiceDate = `${date.toLocaleString('default', { month: 'long' })} ${CustomerDetail.payDate} , ${date.getFullYear()}`;

    doc.setFontSize(15);
    doc.text(title, 420, 70);
    doc.setFontSize(11);
    doc.text(InvoiceNumberText+InvoiceNumber, 370, 160);
    doc.text(InvoiceDateText+InvoiceDate, 370, 180);
};

export const HeaderCompany = (doc,margin,startPositionY) => {
    const companyTaxText = "Tax ID: ";

    startPositionY += margin;
    doc.setFontSize(13);
    doc.text(CompanyDetail.name, margin, startPositionY);

    startPositionY += doc.internal.getLineHeight()+ 5;
    doc.setFontSize(11);
    let Addresslines = doc.splitTextToSize(CompanyDetail.address, 350)
    doc.text(Addresslines, margin, startPositionY);

    startPositionY += Addresslines.length * doc.internal.getLineHeight()+ 5;
    doc.text(CompanyDetail.phone, margin, startPositionY);

    startPositionY += doc.internal.getLineHeight()+ 5;
    doc.text(companyTaxText+CompanyDetail.tax, margin, startPositionY);

    return startPositionY;
};

export const HeaderCustomer = (doc,margin,startPositionY) => {
    const customerAccountNoText = "Account Number: ";
    const customerPhoneText = "Tel: ";
    const customerTaxText = "Tax ID: ";

    startPositionY += margin;
    doc.setFontSize(11);
    doc.text(customerAccountNoText+CustomerDetail.accountNo, margin, startPositionY);

    startPositionY += doc.internal.getLineHeight()+ 5;
    doc.text(CustomerDetail.name, margin, startPositionY);

    startPositionY += doc.internal.getLineHeight()+ 5;
    let Addresslines = doc.splitTextToSize(CustomerDetail.address, 250)
    doc.text(Addresslines, margin, startPositionY);

    startPositionY += Addresslines.length * doc.internal.getLineHeight()+ 5;
    doc.text(customerPhoneText+CustomerDetail.phone, margin, startPositionY);

    startPositionY += doc.internal.getLineHeight()+ 5;
    doc.text(customerTaxText+CustomerDetail.tax, margin, startPositionY);
    
    return startPositionY;
};